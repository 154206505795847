import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createQueryString } from '../../../utilities/utilities';
import './social_login.scss';

const SocialLogin = ({
  provider,
  authToken,
  buttonText,
  iconClass,
  redirectTo,
  redirectToError,
}) => {
  const params = {
    origin: window.location.href,
    redirect_to: redirectTo,
    redirect_to_error: redirectToError,
  };
  const action = `/auth/${provider}${createQueryString(params)}`;

  const renderButton = (
    <button type='submit' className='btn btn-outline-secondary w-100 mt-2 mb-2 pt-2 pb-2'>
      <i data-testid='icon' className={classNames('fab', iconClass)}></i>
      <span className='ms-2 text-gray'>{buttonText}</span>
    </button>
  );

  return (
    <form
      className='social-login'
      data-testid={`social-login-${provider}`}
      action={action}
      method='post'
    >
      <input
        type='hidden'
        data-testid='authenticity_token'
        name='authenticity_token'
        value={authToken}
      />
      {renderButton}
    </form>
  );
};

SocialLogin.propTypes = {
  provider: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  iconClass: PropTypes.string,
  redirectTo: PropTypes.string,
  redirectToError: PropTypes.string,
};

SocialLogin.defaultProps = {
  buttonText: 'Login',
  iconClass: '',
  redirectTo: window.location.href,
  redirectToError: window.location.href,
};

export default SocialLogin;
